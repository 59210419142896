import { React } from "react";  
import { Link, useLocation } from "react-router-dom";
import "./Navigation.css";

function Navigation({ isShowMenu, setIsShowMenu }) {
    const location = useLocation(); // Get the current path

    // Close the burger menu if it's open
    const handleCloseBurgerMenu = () => {
        if (isShowMenu) setIsShowMenu(false);
    };

    // Determine the active link based on URL
    const getActiveClass = (prefix) => 
        location.pathname.startsWith(prefix) ? "navigation__link_active" : "";

    return (
        <>
            <Link
                id="1"
                className={`navigation__link navigation__link_main ${getActiveClass("/center/")}`}
                to="/center/2023"
                onClick={handleCloseBurgerMenu}
            >
                Федеральный бюджет
            </Link>
            <Link
                id="2"
                className={`navigation__link navigation__link_main ${getActiveClass("/regions/")}`}
                to="/regions/moscow/2022"
                onClick={handleCloseBurgerMenu}
            >
                Региональные бюджеты
            </Link>
            <Link
                id="3"
                className={`navigation__link navigation__link_main ${getActiveClass("/extra/")}`}
                to="/extra/2020"
                onClick={handleCloseBurgerMenu}
            >
                Внебюджетные фонды
            </Link>
            <Link
                id="4"
                className={`navigation__link navigation__link_main ${getActiveClass("/methodology")}`}
                to="/methodology"
                onClick={handleCloseBurgerMenu}
            >
                О бюджете
            </Link>
        </>
    );
}

export default Navigation;

