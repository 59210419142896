import { METRICS, ARTICLES, ARTICLES_CENTER_LINE } from '../../utils/constants/constants';

const dropdowns = (setItem, setIndex, setYear, mode, center) => {
  const allDropdowns = [
    {
      id: "1",
      label: "Статья",
      items: mode === "line" && center !== "yes"
        ? [
            { main: ARTICLES[0], sub: ARTICLES.filter(item => item.id >= 3 && item.id <= 16) },
            { main: ARTICLES[1], sub: ARTICLES.filter(item => item.id >= 17 && item.id <= 18) },
            { main: ARTICLES[2], sub: [] } // No sub-items, but still maintains structure
          ]
        : [
            { main: ARTICLES_CENTER_LINE[0], sub: ARTICLES_CENTER_LINE.filter(item => item.id >= 3 && item.id <= 17) },
            { main: ARTICLES_CENTER_LINE[1], sub: ARTICLES_CENTER_LINE.filter(item => item.id >= 18 && item.id <= 19) },
            { main: ARTICLES_CENTER_LINE[2], sub: [] }
          ],
      setter: setItem,
      keyProp: "id",
      textProp: "name_ru",
      className: "map__dropdown-list_metrics"
    },
    {
      id: "2",
      label: "Единицы измерения",
      items: METRICS.filter(m => m.id >= 0 && m.id <= 7),
      setter: setIndex,
      keyProp: "id",
      textProp: "name",
      className: "map__dropdown-list_items"
    },
    {
      id: "3",
      label: "Год",
      items: [{ id: 2022, name: "2022" }, { id: 2021, name: "2021" }, { id: 2020, name: "2020" }],
      setter: setYear,
      keyProp: "id",
      textProp: "name",
      className: "map__dropdown-list_years"
    }
  ];

  const allMainDropdowns = [
    {
      id: "1",
      label: "Статья",
      items: [
        { main: ARTICLES[0], sub: ARTICLES.filter(item => 3 <= item.id && item.id <= 16) },
        { main: ARTICLES[1], sub: ARTICLES.filter(item => 17 <= item.id && item.id <= 18) },
        { main: ARTICLES[2], sub: [] } // No sub-items, but still maintains structure
      ],
      setter: setItem,
      keyProp: "id",
      textProp: "name_ru",
      className: "map__dropdown-list_metrics"
    },
    {
      id: "2",
      label: "Единицы измерения",
      items: METRICS.filter(m => 0 <= m.id && m.id <= 7),
      setter: setIndex,
      keyProp: "id",
      textProp: "name",
      className: "map__dropdown-list_items"
    },
    {
      id: "3",
      label: "Год",
      items: [{ id: 2022, name: "2022" }, { id: 2021, name: "2021" }, { id: 2020, name: "2020" }],
      setter: setYear,
      keyProp: "id",
      textProp: "name",
      className: "map__dropdown-list_years"
    }
  ];

  // If mode is "line", return only the first two dropdowns.
  return mode === "line" ? allDropdowns.slice(0, 2) : allMainDropdowns;
};

export { dropdowns };
