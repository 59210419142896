import React from 'react';
import './Loading.css';

const Loading = ({center, main}) => {
 
  return (
  <div className={`loading ${main === 'main' ? '' : 'loading_treemap'}`}>
    <div className="loading__container">
      <div className="loading__spinner loading__spinner_red" />
      <div className="loading__spinner loading__spinner_green" />
      <div className="loading__spinner loading__spinner_blue" />
    </div>
    {center === "yes" ? <p className='loading__text'>Cкоро данные загрузятся</p> : ""}
    </div>
  );
};

export default Loading;