import React, { useEffect, useRef, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import uid from './uid'
import './Treemap.css'
import * as d3 from 'd3'
// import gdata from '../../utils/constants/test_total.json'
// import feddata from '../../utils/constants/fed_test_total.json'
import { Context } from '../../contexts/CurrentUserContext'
import { useResizeObserver } from '../../utils/constants/useResizeObserver'
import {s,p,f} from '../../utils/constants/formatSpecifiers'
import tableIcon from '../../images/table-icons/table.svg'
import treemapIcon from '../../images/table-icons/treemap.svg'

const Treemap = ({onChange, navigation, data}) => { 
const contRef = useRef();
const dimensions = useResizeObserver(contRef);
const [mousePosition, setMousePosition] = useState({ x: 0, y: 0});
const [tooltipOpacity, setTooltipOpacity] = useState(0);
const [tooltipName, setTooltipName] = useState(null);
const [visibleNodes, setVisibleNodes] = useState([]); // Store visible nodes
const [showTable, setShowTable] = useState(false); // Toggle between treemap and table view
const svgRef = useRef();
const navigate = useNavigate();


useEffect(() =>{
        // Check if data is missing or doesn't have the expected structure
  if (!data || !data.children) {
    navigate('/not-found'); // Redirect to NotFound page
    return;
    }

  if (!dimensions || showTable) return; // если верно, то идем дальше
    const width =  dimensions.width
    const height = dimensions.height

    // const handleVisibleNodesChange = (nodes, currentNode) => {
    //   setVisibleNodes(nodes);
    //   // setCurrentNode(currentNode); // Save the current node
    // };

    const x = d3.scaleLinear().rangeRound([0, width]);
    const y = d3.scaleLinear().rangeRound([0, height]);

    function tile(node, x0, y0, x1, y1) {
      d3.treemapBinary(node, 0, 0, width, height);
      for (const child of node.children) {
        child.x0 = x0 + child.x0 / width * (x1 - x0 );
        child.x1 = x0 + child.x1 / width * (x1 - x0);
        child.y0 = y0 + child.y0 / height * (y1 - y0);
        child.y1 = y0 + child.y1 / height * (y1 - y0);
      }
    }

    const treemap = data => d3.treemap()
          .tile(tile)
          (d3.hierarchy(data)
          // делим как хотим здесь
          .sum(d => d.value/1000000)
          .sort((a, b) => b.value - a.value))

    // console.log(treemap(data))
    // console.log(data)
    let root = treemap(data)

    // Recursive function to calculate the total sum of each node, including all children
    const calculateDescendantSums = (node) => {
      if (!node.children) {
        return node.value || 0;
      }
      node.value = node.children.reduce((sum, child) => {
        return sum + calculateDescendantSums(child);
      }, 0);
      return node.value;
    };

    // Calculate sums for the initial root node
    calculateDescendantSums(root);

    // Function to extract each child with the computed sum
    const getRootChildrenSums = (node) => {
      return node.children ? node.children.map((child) => ({ name: child.data.name, sum: child.value })) : [];
    };

    // Pass initial visible nodes with their summarized values
    setVisibleNodes(getRootChildrenSums(root));
    // console.log(getRootChildrenSums(root))
    // но при клике на regionList place заменяем на region
    //Как реализовать ???
    //https://stackoverflow.com/questions/63249124/how-to-update-d3-chart-when-receiving-new-data
        //Панель. положение панели с навигацией
    function position(group, root) {
      group.selectAll("g")
          .attr("transform", d => d === root ? `translate(0,-40)` : `translate(${x(d.x0)},${y(d.y0)})`)
          .select("rect")
          .attr("width", d => d === root ? width : x(d.x1) - x(d.x0))
          .attr("height", d => d === root ? 30 : y(d.y1) - y(d.y0));
    }

    //Панель. как высвечивается текст в поле с навигацией
    // const name = d => d.ancestors().reverse().map(d => d.data.name).join(" -> ")
    // const name = (d) => {
    //   return d.ancestors().reverse().map(d => d.data.name).join(" -> ");
    // }
  
    d3.select("svg").remove()
    //функция, которая располагает квадраты по положению
  
    const svg = d3.select(svgRef.current)
        .append("svg")
        .attr("viewBox", [40, -30, width-55, height+30])
        .attr("width", width)
        .attr("height", height)
        .style("font", "14px sans-serif");
        //в tooltips тоже нужно ставить svgRef.current

    // const tooltip = d3.select("body").append("div")
    //     .attr("class", "treemap__tooltip")
    //     .style("opacity", 0)
        // .style( this.getBoundingClientRect())
        // .on("mousemove", function(event,d) {
        //   console.log(this.parentNode.getBoundingClientRect())
        //   })
        // .data(d => d===root ? null : data )

    svg.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")  

    // Send the currently visible nodes
    setVisibleNodes(getRootChildrenSums(root));
    
    let group = svg.append("g")
        .call(render, root)

    function render(group, root) {
      const node = group
        .selectAll("g")
        .data(root.children.concat(root))
        .join("g");
      //https://www.color-hex.com/color-palette/8228
       
      const myColor = d3.scaleOrdinal()
        //https://ru.pinterest.com/pin/288160076149614620/
        //[darkblue, red, gray, blue, lightblue, darkred]
        // .range(["#313559", "#B75743", "#DCDCDC", "#4F6C8A","#8196A9", "#94412F"])
        .range(["#507B58", "#f6cf2d", "#AB3131","#084808", "#ffd700", "#4f7942", "#7d0011"])
      const myColorText = d3.scaleOrdinal()
      //FFFFFF - white, 000000 - black
      .range(["#FFFFFF", "#191919",  "#FFFFFF", "#FFFFFF","#191919", "#FFFFFF", "#FFFFFF"])

      const myColorTextWhite = d3.scaleOrdinal()
      .range(["#FFFFFF", "#FFFFFF",  "#FFFFFF", "#FFFFFF","#FFFFFF", "#FFFFFF", "#FFFFFF"])

    // function clicker (event, d, root) {
    //   if (d === root){
    //   return zoomout(d)
    //   } else {
    //   return zoomin(d)
    //   }
    // }

    
    //Определяем положение тултипа в зависимости от того, близко ли к правому краю точка X
    const tooltipXPosition = (event) => {
      const tooltipElement = document.querySelector('.treemap__tooltip');
      if (!tooltipElement) return "0px";
  
      const tooltipWidth = parseInt(getComputedStyle(tooltipElement).width.replace("px", ""))+18
      // тултип в desktop'е
      if (width>800){
        // console.log(tooltipWidth) // ширина тултипа
        // console.log(window.screen.width) // ширина viewport'а
        // console.log(width) // ширина контейнера
        // console.log(event.pageX) // относительно страницы
        // console.log(event.layerX) // относительно контейнера
        // console.log(event) // относительно контейнера
      if (window.screen.width-event.pageX > (tooltipWidth+100)) {
      return (event.offsetX) + "px"}
      else {
      return (event.offsetX - tooltipWidth) + "px"
      }
    }else{
      if (window.screen.width-event.pageX > (tooltipWidth-18)) {
        return (event.offsetX) + "px"}
        else {
        return (event.offsetX-tooltipWidth) + "px"
        }
      }
    }


    function tooltipYPosition(event) {
      const tooltipElement = document.querySelector('.treemap__tooltip');
      if (!tooltipElement) return event.layerY;
        
      const tooltipHeight = tooltipElement.getBoundingClientRect().height;

        // console.log(window.screen.height) // ширина viewport'а
        // console.log(tooltipHeight)
        // console.log(height) // ширина контейнера
        // console.log(height - event.offsetY) // высота контейнера
        // // console.log(event.pageY) // относительно страницы
        // console.log(event.layerY) // относительно контейнера
        // console.log(event.offsetY) // относительно контейнера
        // console.log(window.screen.height)
        // console.log(window.screen.height - event.pageY)
        // console.log(event.pageY - window.screen.height)
          // 💡 Restore original style
      
      if (height - event.layerY > tooltipHeight) {
        return (event.layerY) + "px"; // Show below cursor if near top
      } else {
        return (event.layerY - tooltipHeight) + "px"; // Show above otherwise
      }
    }

    
    function handleMouseMove(event, name, value) {
      const [x, y] = d3.pointer(event, svgRef.current);
      const Xadj = tooltipXPosition(event) 
      const Yadj = tooltipYPosition(event) 
      setMousePosition({ x: Xadj, y: Yadj });
      setTooltipOpacity(1)
      setTooltipName([name, value])
  }

    function addTooltip(selection){
      if(width>900){
      selection
      .on("mousemove", function(e, d){
        if(d !== root ){
          handleMouseMove(e, d.data.name, f(p(d.value)))
        }
      })
      .on("mouseout", function(d) {
        setTooltipOpacity(0)
        })
      }else{
        selection
        .on("mousemove", function(e, d){
          if(d !== root ){
            handleMouseMove(e, d.data.name, f(p(d.value)))
          }
        })
          .on("dblclick", function(d) {
            setTooltipOpacity(0)
          })
          .on("mouseout", function(d) {
            setTooltipOpacity(0)
          })
        }
      }


      function clicker(event, d){
        return d === root ? zoomout(root) : zoomin(d)
      }

      const clickFunction = (selection) => {
        if (width>900){
        selection.on ("click", clicker)                
      } else{
        selection.on ("dblclick", clicker) 
      }
      }
      


      node.filter(d => d === root ? d.parent : d.children)
          .attr("cursor", "pointer")
          .call(clickFunction)


      //Панель. Если значение root, то панель, иначе - тримап
      node.append("rect")
          .attr("id", d => (d.leafUid = uid("leaf")).id)
          .attr("fill", d => d === root ? "#191919" : d.children ? myColor(d) : myColor(d))
          .attr("width", (d) => x(d.x1) - x(d.x0))
          .attr("height", (d) => d.y1 -d.y0)
          //рамка между квадратами
          .attr("stroke",  d => d === root ? "#191919" : "#fff")
          .attr("cursor", "pointer")
          //подсвечивание и tooltips
          .call(addTooltip)


      node.append("clipPath")
          .attr("id", d => (d.clipUid = uid("clip")).id)
          .append("use")
          .attr("xlink:href", d => d.leafUid.href);
  
          function wrapText(text, d) {
            text.each(function() {
              const widthParent = this.parentNode.getAttribute('width-parent')
              const widthCurrent = this.parentNode.getAttribute('width-current')
              const widthRect = widthCurrent/widthParent*width
              const heightParent = this.parentNode.getAttribute('height-parent')
              const heightCurrent = this.parentNode.getAttribute('height-current')
              const heightRect = heightCurrent/heightParent*height 
              const parent = d3.select(this.parentNode)
              // console.log(this.parentNode)
              // const value = parent.value
              const value = () => {
                if (isNaN(widthRect)){
                  return " "
                }else{
                  return f(p((d3.select(this.parentNode).datum().value)))
                }
              }
              const fontSize = ()=> {
                if (isNaN(widthRect)){
                  return 20
                }else{
                  return Math.max(Math.min(width/5, heightRect/2, Math.sqrt((widthRect*widthRect + heightRect*heightRect))/25), 9)
                }
              }
              // console.log(value())
              var text =  d3.select(this).attr("font-size", d => {
                 return fontSize()
                }),
                  words = text.text().split(/\s+/).reverse(),
                  word,
                  line = [],
                  lineNumber = 0,
                  lineHeight = 1.1,
                  y = text.attr("y"),
                  dy = 0,
                  tspan = text.text(null).append("tspan").attr("x", 5).attr("y", y).attr("dy", dy + "em")
                  if (widthRect <50) {
                    line.pop();
                    tspan.text(line.join(" "));
                    tspan = text.append("tspan").attr("x", 5).attr("y", y).text('...')   
                  }else{
                  while (word = words.pop()) {
                    line.push(word);  
                    tspan.text(line.join(" "))
                  if ((widthRect >50 && tspan.node().getComputedTextLength() > widthRect && line.length !== 1)) {
                  line.pop();
                  tspan.text(line.join(" "));
                  line = [word];
                  // console.log(word)
                  tspan = text.append("tspan").attr("x", 5).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word).attr("color", "black")
                }
              }
            }
              if (widthRect <50){
                tspan = text.append('tspan').attr("x", 5).attr("y", y).text('...')
              } else {
                tspan = text.append('tspan').attr("x", 5).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(value())
              }
            })
          }
      

      node.append("text")
          .attr("id", d => (d.leafUid = uid("leaf")).id)
          .attr("width-parent", d => d === root ? d : d.parent.x1 - d.parent.x0)
          .attr("width-current", d => d.x1 - d.x0)
          .attr("height-parent", d => d === root ? d : d.parent.y1 - d.parent.y0)
          .attr("height-current", d => d.y1 - d.y0)
          .attr("clip-path", d => d.clipUid = uid("clip").id)
          .attr("font-family", "Gilroy")
          .attr("fill", function (d) {
            if (d!==root){
            return  myColorText(d)
          }else{
            return  "white"
          }
        })
          .call(addTooltip)
          .selectAll("tspan")
          .data(d => d === root ? (`↑Назад`).split() : (d.data.name).split(/(?=[A-Z][^A-Z])/g))
          // } === root ? myColorTextWhite(d) : myColorText(d))
          // `↑Назад${this.on("mousemove").d}`
          .join("tspan")
          .text(d => d)
          // .style("fill", d => d === root ? "white" : myColorText(d))
          .attr("x", 3)
          .attr("y", (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`)
          // .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
          .attr("font-weight", (d, i, nodes) => i === nodes.length - 1 ? "bold" : null)
          .call(wrapText)
          //Как делать перенос текста - wrap
          //https://stackoverflow.com/questions/24784302/wrapping-text-in-d3
      group.call(position, root);

      onChange(navigation(root)) //передается из regions для управления поиском
    } 
  
  
    // When zooming in, draw the new nodes on top, and fade them in.
    function zoomin(d) {
      const group0 = group.attr("pointer-events", "none");
      const group1 = group = svg.append("g").call(render, d);
  
      x.domain([d.x0, d.x1]);
      y.domain([d.y0, d.y1]);
  
      svg.transition()
          .duration(750)
          .call(t => group0.transition(t).remove()
          .call(position, d.parent))
          .call(t => group1.transition(t)
            .attrTween("opacity", () => d3.interpolate(0, 1))
            .call(position, d));

      // Send the new visible nodes to the parent component
      setVisibleNodes(getRootChildrenSums(d));
    }
  
    // When zooming out, draw the old nodes on top, and fade them out.
    function zoomout(d) {
      const group0 = group.attr("pointer-events", "none");
      const group1 = group = svg.insert("g", "*").call(render, d.parent);
  
      x.domain([d.parent.x0, d.parent.x1]);
      y.domain([d.parent.y0, d.parent.y1]);
  
      svg.transition()
          .duration(750)
          .call(t => group0.transition(t).remove()
            .attrTween("opacity", () => d3.interpolate(1, 0))
            .call(position, d))
          .call(t => group1.transition(t)
            .call(position, d.parent));
      // Send the new visible nodes to the parent component
      setVisibleNodes(getRootChildrenSums(d.parent));
            // console.log(mousePosition)
    }
    // https://stackoverflow.com/questions/61228317/update-d3-data-react-hooks
      }, [data, dimensions])
      

    return (
    <section>
      <>
      <div className="treemap__view-toggle">
          <button
            onClick={() => setShowTable(false)}
            className={`treemap__view-button ${!showTable ? 'treemap__view-button_active' : ''}`}
            aria-label="Show Treemap"
          >
            <img src={treemapIcon} alt="Treemap Icon" className="treemap__icon" />
          </button>

          <button
            onClick={() => setShowTable(true)}
            className={`treemap__view-button ${showTable ? 'treemap__view-button_active' : ''}`}
            aria-label="Show Table"
          >
            <img src={tableIcon} alt="Table Icon" className="treemap__icon" />
          </button>
        </div>
          <div id="treemap__container" className={`treemap__container ${showTable ? 'hidden' : ''}`} ref={contRef}>
          <div className={`treemap__table-container ${showTable ? '' : 'treemap__table-container_hidden'}`}>
            <table className="treemap__table" >
              <thead>
                <tr>
                  <th className="treemap__table-title">Название</th>
                  <th className="treemap__table-title">Величина (млн руб.)</th>
                </tr>
              </thead>
              <tbody>
                {visibleNodes.map((item, index) => (
                  <tr key={index}>
                    <td className="treemap__table-text">{item.name}</td>
                    <td className="treemap__table-text">{f(p(item.sum))}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div> 
            <div id="treemap" className='treemap' ref={svgRef}>
            {tooltipName && mousePosition && (
            <div style={{top: mousePosition.y, left: mousePosition.x, opacity: tooltipOpacity }} className='treemap__tooltip'>{`${tooltipName[0]}\n${tooltipName[1]} млн руб.`}
            </div>)}
            </div>
            </div>
            <div className='treemap_test' ></div>
            {/* <button>Click_zoomout</button> */}
            </>
            </section>
            )
    }
    //clicker(root) //onClick={clicker(root)}
    export default Treemap

    //about dynamics https://stackoverflow.com/questions/17323479/d3-js-update-treemap-dynamically