const URL = 'https://api.budgetdata.ru'
//const URL = 'http://localhost:3000'
const APIKEY = 'fJ;adsfnvsbd123kdsF'

// Все возможные единицы измерения
const METRICS = [
        {id: 0, currency: '0', degree: 1000000000, name: "млрд руб.", percapita:'0', perproduct:'0',},
        {id: 1, currency: '1', degree: 1000000000, name: "млрд $",  percapita:'0', perproduct:'0'},
        {id: 2, currency: '0', degree: 1000000, name: "млн руб.", percapita:'0', perproduct:'0'},
        {id: 3, currency: '1', degree: 1000000, name: "млн $",  percapita:'0', perproduct:'0'},
        {id: 4, currency: '0', degree: 1000000000, name: "тыс. руб./чел.", percapita:'1', perproduct:'0'},
        {id: 5, currency: '1',degree: 1000000000, name: "тыс. $/чел.", percapita:'1', perproduct:'0'},
        {id: 6, currency: '0', degree: 1000000000, name: "% ВРП", percapita:'0', perproduct:'1'}
    ]

const METRICS_CENTER_LINE = [
        {id: 0, currency: '0', degree: 1000000000, name: "млрд руб.", percapita:'0', perproduct:'0',},
        {id: 1, currency: '1', degree: 1000000000, name: "млрд $",  percapita:'0', perproduct:'0'},
        {id: 2, currency: '0', degree: 1000000, name: "млн руб.", percapita:'0', perproduct:'0'},
        {id: 3, currency: '1', degree: 1000000, name: "млн $",  percapita:'0', perproduct:'0'},
        {id: 4, currency: '0', degree: 1000000000, name: "тыс. руб./чел.", percapita:'1', perproduct:'0'},
        {id: 5, currency: '1',degree: 1000000000, name: "тыс. $/чел.", percapita:'1', perproduct:'0'},
        {id: 6, currency: '0', degree: 1000000000, name: "% ВВП", percapita:'0', perproduct:'1'}
]

    // Все возможные статьи
const ARTICLES = [
        {id:0, name_eng: 'expenses', name_ru:'Расходы', level:0, parent: -1, group_id:0},
        {id:1, name_eng: 'revenues', name_ru: 'Доходы', level:0, parent: -1, group_id:1},
        {id:2, name_eng: 'deficit', name_ru: 'Дефицит/Профицит', level:0, parent: -1, group_id:2},
        {id:3, name_eng: 'state_questions', name_ru: 'Общегосударственные вопросы', level:1, parent: 0, group_id:0},
        {id:4, name_eng: 'national_defense', name_ru: 'Национальная оборона', level:1, parent: 0, group_id:1},
        {id:5, name_eng: 'national_security', name_ru: 'Национальная безопасность и \n правоохранительная деятельность', level:1, parent: 0, group_id:2},
        {id:6, name_eng: 'national_economy', name_ru: 'Национальная экономика', level:1, parent: 0, group_id:3},
        {id:7, name_eng: 'housing_services', name_ru: 'Жилищно-коммунальное \n хозяйство', level:1, parent: 0, group_id:4},
        {id:8, name_eng: 'environment', name_ru: 'Охрана окружающей среды', level:1, parent: 0, group_id:5},
        {id:9, name_eng: 'education', name_ru: 'Образование', level:1, parent: 0, group_id:6},
        {id:10, name_eng: 'culture', name_ru: 'Культура, кинематография', level:1, parent: 0, group_id:7},
        {id:11, name_eng: 'health', name_ru: 'Здравоохранение', level:1, parent: 0, group_id:8},
        {id:12, name_eng: 'social_policy', name_ru: 'Социальная политика', level:1, parent: 0, group_id:9},
        {id:13, name_eng: 'sport', name_ru: 'Физическая культура и спорт', level:1, parent: 0, group_id:10},
        {id:14, name_eng: 'media', name_ru: 'Средства массовой информации', level:1, parent: 0, group_id:11},
        {id:15, name_eng: 'debt', name_ru: 'Обслуживание государственного \n (муниципального) долга', level:1, parent: 0, group_id:12},
        {id:16, name_eng: 'transfers', name_ru: 'Межбюджетные трансферты', level:1, parent: 0, group_id:13},
        {id:17, name_eng: 'taxes', name_ru: 'Налоговые и \n неналоговые доходы', level:1, parent: 1, group_id:0},
        {id:18, name_eng: 'subsidies', name_ru: 'Безвозмездные поступления', level:1, parent: 1, group_id:1},
    ]

const ARTICLES_CENTER_LINE = [
        {id:0, name_eng: 'expenses', name_ru:'Расходы', level:0, parent: -1, group_id:0},
        {id:1, name_eng: 'revenues', name_ru: 'Доходы', level:0, parent: -1, group_id:1},
        {id:2, name_eng: 'deficit', name_ru: 'Дефицит/Профицит', level:0, parent: -1, group_id:2},
        {id:3, name_eng: 'hidden_expenses', name_ru: 'Скрытые расходы', level:1, parent: 0, group_id:0},
        {id:4, name_eng: 'state_questions', name_ru: 'Общегосударственные вопросы', level:1, parent: 0, group_id:1},
        {id:5, name_eng: 'national_defense', name_ru: 'Национальная оборона', level:1, parent: 0, group_id:2},
        {id:6, name_eng: 'national_security', name_ru: 'Национальная безопасность и \n правоохранительная деятельность', level:1, parent: 0, group_id:3},
        {id:7, name_eng: 'national_economy', name_ru: 'Национальная экономика', level:1, parent: 0, group_id:4},
        {id:8, name_eng: 'housing_services', name_ru: 'Жилищно-коммунальное \n хозяйство', level:1, parent: 0, group_id:5},
        {id:9, name_eng: 'environment', name_ru: 'Охрана окружающей среды', level:1, parent: 0, group_id:6},
        {id:10, name_eng: 'education', name_ru: 'Образование', level:1, parent: 0, group_id:7},
        {id:11, name_eng: 'culture', name_ru: 'Культура, кинематография', level:1, parent: 0, group_id:8},
        {id:12, name_eng: 'health', name_ru: 'Здравоохранение', level:1, parent: 0, group_id:9},
        {id:13, name_eng: 'social_policy', name_ru: 'Социальная политика', level:1, parent: 0, group_id:10},
        {id:14, name_eng: 'sport', name_ru: 'Физическая культура и спорт', level:1, parent: 0, group_id:11},
        {id:15, name_eng: 'media', name_ru: 'Средства массовой информации', level:1, parent: 0, group_id:12},
        {id:16, name_eng: 'debt', name_ru: 'Обслуживание государственного \n (муниципального) долга', level:1, parent: 0, group_id:13},
        {id:17, name_eng: 'transfers', name_ru: 'Межбюджетные трансферты', level:1, parent: 0, group_id:14},
        {id:18, name_eng: 'taxes', name_ru: 'Налоговые и \n неналоговые доходы', level:1, parent: 1, group_id:0},
        {id:19, name_eng: 'subsidies', name_ru: 'Безвозмездные поступления', level:1, parent: 1, group_id:1},
    ]



const FUNDS =[
    {id:0, name_eng: 'pens', name_ru:'Пенсионный фонд'},
    {id:1, name_eng: 'soc', name_ru: 'Фонд социального страхования'},
    {id:2, name_eng: 'med', name_ru: 'Фонд обязательного медицинского страхования'},
]

export {URL, APIKEY, METRICS, METRICS_CENTER_LINE, ARTICLES, ARTICLES_CENTER_LINE, FUNDS}