import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './Regions.css';
import { regionsSchema } from '../../utils/schema';
import Treemap from '../Treemap/Treemap';
import RegionsList from '../RegionsList/RegionsList';
import { URL, APIKEY } from '../../utils/constants/constants';
import { s, p, f } from '../../utils/constants/formatSpecifiers';
import data from '../../utils/constants/region_codes.json';
import { regionList } from '../../utils/constants/actions';
import image from '../../images/logo.svg';
import LineGraph from '../LineGraph/LineGraph'
import Loading from '../Loading/Loading';

function Regions({ state, dispatch }) {
  const { name, year: yearParam } = useParams();
  const navigate = useNavigate();
  const [year, setYear] = useState(Number(yearParam) || 2021);
  const [regionsData, setRegionsData] = useState(null);
  const [regionsName, setRegionsName] = useState("");
  const [loading, setLoading] = useState(false);
  const navRef = useRef(null);
  const [value, setValue] = useState();
  const buttonRef = useRef(null);
  const [area, setArea] = useState('');
  const panelRef = useRef();
  
  // This function now prioritizes the URL param over the state.
  function getRegionNameFromUrl() {
    if (name) return name; // Already in proper format from URL
    const region = data.find(item => item.id === state.counter);
    return region ? region.name_eng.replace(/\s+/g, '-').toLowerCase() : 'unknown';
  }

  const name_eng = getRegionNameFromUrl();

    // Define handleNameChange here
    const handleNameChange = (value) => {
        setValue(value);
      }

  useEffect(() => {
      if (panelRef.current) {
        panelRef.current.scrollTop = panelRef.current.scrollHeight;
      }
  }, [value]); // or [centerData] if that's more appropriate
            
  // On mount, update state.counter based on the URL parameter.
  useEffect(() => {
    if (name) {
      const region = data.find(
        item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name
      );
      if (region) {
        // Only dispatch if the counter is different.
        if (region.id !== state.counter) {
          dispatch(regionList(region.id));
        }
      }
    }
  }, [name, dispatch]); // Removed state.counter from dependency to avoid re-triggering unnecessarily.

  // Sync URL when `year` changes.
  useEffect(() => {
    const currentPath = `/regions/${name_eng}/${year}`;
    if (window.location.pathname !== currentPath) {
      navigate(currentPath, { replace: true });
    }
  }, [year, name_eng, navigate]);

  // Fetch data on year or counter change.
  useEffect(() => {
    setLoading(true);
    const counterForApi = state.counter > 0 ? state.counter - 1 : 0;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-api-key': APIKEY },
      body: JSON.stringify({ counter: counterForApi, year }),
    };
    fetch(`${URL}/regions`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setRegionsData(res.data);
        setRegionsName(res.name);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [state.counter, year]);
  

    useEffect(() => {
        // After the value updates, add the "active" class to trigger the transition.
        const listItems = document.querySelectorAll('.regions__map');
        listItems.forEach(item => {
          item.classList.remove('active');
          // Force a reflow if necessary (e.g., via item.offsetWidth) then:
          setTimeout(() => item.classList.add('active'), 50);
        });
      }, [value]);

      
  
  const navigation = (d) => {
    return d.ancestors().reverse().slice(1).map((d) => (
      <li key={d.data.name} className='regions__map'>
        <span className='regions__span'>&#8595;</span>
        
        <p className='regions__text'>{d.data.name}</p>
         <p className='regions__text-value'> ({f(p(d.value))} млн руб.)</p>
        <div className='regions__arrow'></div>
      </li>
    ));
  };

  const onChange = (e) => {
    setArea(e.target.value);
  };

  function handleClickOutside(event) {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setArea('');
    }
  }

  function handleKeydown(e) {
    if (e.key === 'Escape') setArea('');
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const handleRegionChange = (item) => {
    if (typeof item === 'number') {
      dispatch(regionList(item));
      setArea('');
    }
    const regionListName = data
      .find(d => d.id === item)
      .name_eng.replace(/\s+/g, '-')
      .toLowerCase();
    navigate(`/regions/${regionListName}/${year}`);
  };

  const onSearch = (searchTerm) => {
    setArea(searchTerm);
  };

  const regionDetails = data.find(
    item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name
  );
  const regionNameRP = regionDetails.name_rp
  
  // console.log(regionNameRP)
  useEffect(() => {
    if (regionDetails && regionNameRP) {
      // Signal that the page is fully rendered
      window.prerenderReady = true;
    }
  }, [regionDetails, regionNameRP]);
  
    useEffect(() => {
        const container = document.querySelector('.regions__test');
        if (container) {
            container.scrollLeft = container.scrollWidth; // Scrolls to the last item
        }
    }, [value]); // Triggers when value updates

    useEffect(() => {
        const textElement = document.querySelector('.regions__text');
        if (textElement) {
            textElement.scrollTop = 0; // Resets scroll position to top
        }
    }, [value]); // Runs when text content updates

  return (
    <>
      {regionDetails && (
        <Helmet>
          <title>{`Бюджет ${regionNameRP} за ${year} год`}</title>
          <meta
            name="description"
            content={`Понятный бюджет ${regionNameRP} за ${year} год. Интерактивная визуализация доходов и расходов за ${year} год`}
          />
          <meta property="og:title" content={`Бюджет ${regionNameRP} за ${year} год`} />
          <meta
            property="og:description"
            content={`Понятный бюджет ${regionNameRP} за ${year} год. Интерактивная визуализация доходов и расходов`}
          />
          <meta property="og:image" content={image} />
          <meta
            property="og:url"
            content={`https://budgetdata.ru/regions/${name_eng}/${year}`}
          />
          <meta property="og:type" content="website" />
          <link rel="canonical" href={`/regions/${name_eng}/${year}`} />
          <script type="application/ld+json">
          {JSON.stringify(regionsSchema(regionNameRP, name_eng, year))}
        </script>
        </Helmet>
      )}
      <section className="regions__content">
        <div className="regions">
          <div className="regions__list">
            <RegionsList
              className="regions__search"
              setYear={setYear}
              dispatch={dispatch}
              state={state}
              area={area}
              onChange={onChange}
              onSearch={onSearch}
              handleRegionChange={handleRegionChange}
              ref={buttonRef}
            />
            <div className="regions__panel" ref={panelRef}>
                <p className="regions__explanation">
                Кликните <span className="regions__highlight">дважды</span> - перейдете <span className="regions__highlight">на следующий уровень</span>,<br />
                <span className="regions__highlight">один раз</span> - посмотрите <span className="regions__highlight">цифры</span>
                </p>
                <ul className="regions__test">
                  <p className="regions__value">
                    {regionsName}, {year}, млн руб.
                  </p>
                  {value}
              </ul>
              </div>  
          </div>
          <div className="regions__treemap">
            {loading ? (
              <Loading/>
            ) : (
              <Treemap
                year={year}
                state={state}
                ref={navRef}
                navigation={navigation}
                onChange={handleNameChange}
                data={regionsData}
              />
            )}
          </div>
        </div>
        <div className="regions__linegraph">
        {loading ? (
          <>
            <Loading/>
            </>
            ) : (
            <LineGraph name={regionNameRP} state={state} center={"no"} />
          )}
          </div>
      </section>
    </>
  );
  
}

export default Regions;
