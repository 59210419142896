import React, { useState, useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { useResizeObserver } from '../../utils/constants/useResizeObserver';
import {URL, METRICS, ARTICLES, APIKEY, ARTICLES_CENTER_LINE} from '../../utils/constants/constants' 
import { dropdowns } from '../../utils/constants/dropdowns';
import './LineGraph.css';

export default function LineGraph({ state, name, center }) {
  const contRef = useRef();
  const svgRef = useRef();
  const [graphData, setGraphData] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0});
  const [tooltipOpacity, setTooltipOpacity] = useState(0);
  const [tooltipName, setTooltipName] = useState(null);
  const [loading, setLoading] = useState(false);
  const dimensions = useResizeObserver(contRef);
  const [openButton, setOpenButton] = useState({id: 0, value: false});
  const [index, setIndex] = useState(0)
  const [year, setYear] = useState(0)
  const [item, setItem] = useState(0)
  const buttonRef = useRef(null);
  const [toggleButton, setToggleButton] = useState(false);

    const handleOpenButton = (e) => {
        e.preventDefault(); 
        setToggleButton(true)
        setOpenButton(e.target.id);
      }

      const handleCloseButton = (e) => {
        setToggleButton(false) 
    }

    useEffect(() => {
      function handleClickOutside(event) {
        if (
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          // The click is outside
          setOpenButton(0);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    
    
      useEffect(() => {
        function onKeyup(e) {
          //define event listener
          if (e.key === 'Escape')
            setOpenButton(0);
            // setToggleButton(!toggleButton)
        }
          //register our listener
        window.addEventListener('keyup', onKeyup);
          //unregister our listener
        return () => window.removeEventListener('keyup', onKeyup);
      }, []);

    useEffect(() => {
      setLoading(true);
    
      const fetchURL =
        name === 'Федерального бюджета Российской Федерации'
          ? `${URL}/lineGraphDataCenter`
          : `${URL}/lineGraphData`;
    
    // Use ARTICLES_CENTER_LINE if state.counter === -1, otherwise use ARTICLES.
    // const useCenterLine = state.counter === -1;

    const selectedArticle = center === "yes"? ARTICLES_CENTER_LINE[item] : ARTICLES[item];
    
    const counterForApi = state?.counter > 0 ? state.counter - 1 : 0;

    const requestBody = {
      counter: counterForApi,
      id: selectedArticle.id,
      group_id: selectedArticle.group_id,
      parent: selectedArticle.parent,
      level: selectedArticle.level,    
      degree: METRICS[index].degree,
      currency: METRICS[index].currency,
      percapita: METRICS[index].percapita,
      perproduct: METRICS[index].perproduct,
    };
    
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': APIKEY
        },
        body: JSON.stringify(requestBody),
      };
    
      fetch(fetchURL, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log(`Fetched data from ${fetchURL}:`, data);
          if (data && Array.isArray(data.data)) {
            setGraphData(data.data);
          } else {
            console.warn("Unexpected data format:", data);
            setGraphData([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(`Error fetching data from ${fetchURL}:`, error);
          setLoading(false);
        });
    }, [state, name, item, index]);
    

  useEffect(() => {
    d3.select(svgRef.current).select("svg").remove();
    d3.select(".line-graph__tooltip").remove(); // Remove any existing tooltip
    if (!dimensions || !graphData || graphData.length === 0) return;

    // Set margins
    const margin = { top: 20, right: 32, bottom: 50, left: 65 };
    const width = dimensions.width;
    const height = dimensions.height;

    // Process the data
    const data = graphData.map(d => ({
      year: +d.year,
      value: +d.value
    }));

    console.log("Processed graph data:", data);

    // Create the SVG container.
    const svg = d3.select(svgRef.current)
      .append("svg")
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .style("display", "block")
      .style("margin", "auto");

    // X-Axis: add extra padding
    const xExtent = d3.extent(data, d => d.year);
    const xPadding = (xExtent[1] - xExtent[0]) * 0.05;
    const xScale = d3.scaleLinear()
      .domain([xExtent[0] - xPadding, xExtent[1] + xPadding])
      .range([margin.left, width - margin.right]);

    // Y-Axis: Compute extent
    const yMin = d3.min(data, d => d.value);
    const yMax = d3.max(data, d => d.value);
    const tickStep = Math.pow(10, Math.floor(Math.log10(yMax - yMin)) - 1);
    const lowerBound = Math.floor(yMin / tickStep) * tickStep - tickStep;
    const yPaddingTop = (yMax - lowerBound) * 0.1;
    const upperBound = yMax + yPaddingTop;

    const yScale = d3.scaleLinear()
      .domain([lowerBound, upperBound])
      .range([height - margin.bottom, margin.top]);

    // Generate the line path
    const lineGenerator = d3.line()
      .x(d => xScale(d.year))
      .y(d => yScale(d.value));

    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("d", lineGenerator);

    // ✅ **Create tooltip div**
    const tooltip = d3.select("body") // Attach to body so it appears correctly
      .append("div")
      .attr("class", "line-graph__tooltip")
      .style("position", "absolute")
      .style("opacity", 0)
      .style("pointer-events", "none");

    const isPerProduct = METRICS[index].perproduct === '1';
  
      //Определяем положение тултипа в зависимости от того, близко ли к правому краю точка X
      const tooltipPosition = (event) => {
        event.preventDefault()
        const tooltipWidth = parseInt(getComputedStyle(document.querySelector(".line-graph__tooltip")).width.replace("px", ""))+18
        if (window.screen.width-event.pageX > tooltipWidth) {
        return (event.pageX) + "px"}
        else {
        return (event.pageX-tooltipWidth) + "px"
        }
    }

      function handleMouseMove(event, name, value) {
            const [x, y] = d3.pointer(event, svgRef.current);
            const Xadj = tooltipPosition(event) 
            setMousePosition({ x: Xadj, y: y });
            setTooltipOpacity(1)
            setTooltipName([name, value])
        }

        console.log(center)
        console.log(metricsName)
    function addTooltip(selection){
      selection
      .on("mouseover", function(event, d) {
        d3.select(this).attr("r", 8);
        tooltip
          .style("opacity", 1)
          .html(
            (isPerProduct 
               ? `${d.value.toFixed(1)}`
               : d.value.toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, " ")
              )
              + ` <strong>${(center === "yes" && metricsName === "% ВРП") ? "% ВВП" : metricsName}</strong>`
          )
          .style("left", tooltipPosition(event))
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mousemove", function(event) {
        tooltip
        .style("left", tooltipPosition(event))
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mouseout", function() {
        d3.select(this).attr("r", 6);
        tooltip.style("opacity", 0);
      });
      }

    // ✅ **Add dots & working tooltips**
    const circles = svg.selectAll(".dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "line-graph__dot")
      .attr("cx", d => xScale(d.year))
      .attr("cy", d => yScale(d.value))
      .attr("r", 6)
      .attr("fill", "red")
      .attr("stroke", "white")
      .attr("stroke-width", 1)
      .style("cursor", "pointer")
      .call(addTooltip)

    // ✅ **X-Axis**
    const xAxis = d3.axisBottom(xScale)
      .tickValues(data.map(d => d.year))
      .tickFormat(d3.format("d"))
      .tickSize(- (height - margin.top - margin.bottom))
      .tickSizeOuter(0);

    const xAxisG = svg.append("g")
      .attr("class", "line-graph__axis")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(xAxis)
      .lower();

    xAxisG.select(".domain").attr("stroke", "white");
    xAxisG.selectAll("line").attr("stroke", "none"); // ✅ **Remove vertical lines**
    xAxisG.selectAll("text").attr("fill", "white");

    // ✅ **Y-Axis & Grid**
    const yTicks = d3.ticks(lowerBound, upperBound, 5);
    if (!yTicks.includes(lowerBound)) {
      yTicks.unshift(lowerBound);
    }

    const yAxis = d3.axisLeft(yScale)
      .tickValues(yTicks)
      .tickFormat(d => d.toLocaleString("en-US").replace(/,/g, " "));

    const yAxisG = svg.append("g")
      .attr("class", "line-graph__axis")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(yAxis);

    yAxisG.select(".domain").attr("stroke", "white");
    yAxisG.selectAll("text")
      .attr("fill", "white")
      .attr("dx", "-0.5em")
      .style("text-anchor", "end");

    // ✅ **Horizontal Grid**
    svg.append("g")
      .attr("class", "grid")
      .lower()
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(
        d3.axisLeft(yScale)
          .tickValues(yTicks)
          .tickSize(- (width - margin.left - margin.right))
          .tickFormat("")
      )
      .call(g => g.selectAll("line")
        .attr("stroke", "white")
        .attr("stroke-dasharray", "2,2")
        .attr("stroke-linecap", "round"))
      .call(g => g.select(".domain").remove());

  }, [dimensions, graphData]);
    
  const article = center === "yes" ?  ARTICLES_CENTER_LINE : ARTICLES    
  const currentArticle = article.find(article => article.id === item)
  const articleName = currentArticle?.name_ru || "";
  const currentParentName =
    currentArticle && currentArticle.parent !== -1
      ? article.find(article => article.id === currentArticle.parent)?.name_ru || ""
      : "";
  
  const metricsName = METRICS.find(article => article.id === index)?.name || "";


  
  return (
    <div className="line-graph">
      <h3 className="line-graph__title">{`Исторические данные \n${name}`}</h3>
      <h4 className="line-graph__subtitle">{`${currentParentName} - ${articleName}, ${(center === "yes" && metricsName === "% ВРП") ? "%ВВП" : metricsName}`}</h4>
      <div className='line-graph__buttons' ref={buttonRef} onMouseLeave={() => handleCloseButton()}>
                {dropdowns(setItem, setIndex, setYear, "line", center).map(({ id, label, items, setter, keyProp, textProp, className}) => (
                <div key={id} className='map__dropdown'>
                    <button id={id} className="map__dropdown-article-button"
                        onClick={(e) => handleOpenButton(e)} onMouseMove={(e) => handleOpenButton(e)}>
                        {label}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </button>
                    <ul className={`map__dropdown-list ${className} ${toggleButton && openButton === id ? "map__dropdown-list_active" : ""}`} onClick={() => handleCloseButton()} o >
                        {id === "1"
                            ? items.map(({ main, sub }) => (
                                <>
                                    <li key={main.id} className="map__dropdown-item">
                                        <button className="map__dropdown-button map__dropdown-button_main" onClick={() => setter(main.id)}>
                                            <pre className='map__dropdown-text'>{main.name_ru}</pre>
                                        </button>
                                    </li>
                                    {sub.map((item) => (
                                        <li key={item.id} className="map__dropdown-item">
                                            <button className='map__dropdown-button' onClick={() => setter(item.id)}>
                                                <pre className='map__dropdown-text'>{item.name_ru}</pre>
                                            </button>
                                        </li>
                                    ))}
                                </>
                            ))
                            : /* Standard Dropdowns */
                            items.map((item) => (
                                <li key={item[keyProp]} className="map__dropdown-item">
                                    <button className='map__dropdown-button' onClick={() => setter(item[keyProp])}>
                                        <pre className='map__dropdown-text'>{(center === "yes" && item[textProp] === "% ВРП") ? "% ВВП" : item[textProp]}</pre>
                                    </button>
                                </li>
                            ))
                        }

                    </ul>
                </div>
                ))}
            </div>
      <div id="line-graph" className="line-graph__container" ref={contRef}>
        <div className="line-graph__graphics" ref={svgRef}></div>
      </div>
    </div>
  );
}
