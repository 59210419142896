import {React} from "react";
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet-async';
import image from '../../images/logo.svg'; 
import './About.css';

export default function About () {
    return (
    <>
    <Helmet>
      <title>Как работает бюджетная система России?</title>
      <meta name="description" content="Как работает бюджетная система России?"></meta>
      <meta property="og:title" content="О Бюджетной системе России"/>
      <meta property="og:description" content="Как работает бюджетная система России"/>
      <meta property="og:image" content={image} />
      <meta property="og:url" content={`https://budgetdata.ru/methodology`}/>
      <meta property="og:type" content="website"/>
      <link rel="canonical" href="/methodology"></link>
    </Helmet>
      <section className="about">
        <div className="about__container">
        <h1 className="about__title">Источники</h1>
         <p className="about__text">Бюджетные данные взяты из официального сайта Казначейства Российской Федерации</p> 
         <p className="about__text">Среднегодовой курс доллара к рублю взят и посчитан из официального сайта ЦБ:</p>
         <ul className="about__list">
          <li className="about__currency">За 2020 год - 85.8116 USD/RUB</li>
          <li className="about__currency">За 2021 год - 73.6824 USD/RUB</li>
          <li className="about__currency">За 2022 год - 68.3521 USD/RUB</li>
          <li className="about__currency">За 2023 год - 85.8116 USD/RUB</li>
          </ul> 
         </div>
      </section>
    </>
    )
  }