export const mainSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://budgetdata.ru/",
    "author": {
      "@type": "Person",
      "name": "Artem Zubov",
    },
    "name": "Бюджетные данные России",
    "description": "Интерактивная карта и визуализация бюджетов регионов и федерального бюджета России",
    "publisher": {
      "@type": "Person",
      "name": "Artem Zubov",
      "url": "https://budgetdata.ru/",
      "logo": {
        "@type": "ImageObject",
        "url": "https://budgetdata.ru//logo512.png"
      }
    }
  };
  
  export const centerSchema = (year) => ({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": `Федеральный бюджет России за ${year} год`,
    "description": `Интерактивная визуализация федерального бюджета России за ${year} год`,
    "url": `https://budgetdata.ru/center/${year}`,
    "author": {
      "@type": "Person",
      "name": "Artem Zubov",
    },
    "publisher": {
      "@type": "Person",
      "name": "Artem Zubov",
      "url": `https://budgetdata.ru/center/${year}`,
      "logo": {
        "@type": "ImageObject",
        "url": "https://budgetdata.ru//logo512.png"
      }
    },
    "license": "https://opendefinition.org/licenses/cc-by/"
  });
  
  export const regionsSchema = (regionNameRP, name_eng, year) => ({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": `Бюджет ${regionNameRP} за ${year} год`,
    "description": `Интерактивная визуализация бюджета ${regionNameRP} за ${year} год`,
    "url": `https://budgetdata.ru/regions/${name_eng}/${year}`,
    "author": {
      "@type": "Person",
      "name": "Artem Zubov",
    },
    "publisher": {
      "@type": "Person",
      "name": "Artem Zubov",
      "url": `https://budgetdata.ru/regions/${name_eng}/${year}`,
      "logo": {
        "@type": "ImageObject",
        "url": "https://budgetdata.ru//logo512.png"
      }
    },
    "license": "https://opendefinition.org/licenses/cc-by/"
  });
  
  export const extraSchema = (year) => ({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": `Внебюджетные фонды России за ${year} год`,
    "description": `Доходы и расходы Пенсионного фонда, ФОМС и ФСС России за ${year} год. Интерактивная визуализация бюджетов внебюджетных фондов.`,
    "url": `https://budgetdata.ru/extra/${year}`,
    "author": {
      "@type": "Person",
      "name": "Artem Zubov",
    },
    "publisher": {
      "@type": "Person",
      "name": "Artem Zubov",
      "url": `https://budgetdata.ru/extra/${year}`,
      "logo": {
        "@type": "ImageObject",
        "url": "https://budgetdata.ru//logo512.png"
      }
    },
    "license": "https://opendefinition.org/licenses/cc-by/"
  });
  