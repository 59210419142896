import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Center.css';
import { centerSchema } from '../../utils/schema';
import Treemap from '../Treemap/Treemap';
import { URL, APIKEY } from '../../utils/constants/constants';
import { s, p, f } from '../../utils/constants/formatSpecifiers';
import { Helmet } from 'react-helmet-async';
import image from '../../images/logo.svg';
import LineGraph from '../LineGraph/LineGraph'
import Loading from '../Loading/Loading' 

function Center() {
    const { year: yearParam } = useParams();
    const navigate = useNavigate();
    const [year, setYear] = useState(Number(yearParam) || 2023);
    const [centerData, setCenterData] = useState(null);
    const [value, setValue] = useState();
    const [toggleButton, setToggleButton] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const buttonRef = useRef(null);
    const panelRef = useRef();
    const dropdownRef = useRef(null);

    // Update URL when year changes
    useEffect(() => {
        if (yearParam && Number(yearParam) !== year) {
            setYear(Number(yearParam));
        }
    }, [yearParam]);

    useEffect(() => {
        if (panelRef.current) {
          panelRef.current.scrollTop = panelRef.current.scrollHeight;
        }
      }, [value]); // or [centerData] if that's more appropriate
      
    useEffect(() => {
        navigate(`/center/${year}`);
    }, [year, navigate]);

      
    const handleCloseButton = () => {
        setToggleButton(false);
    };

    const handleOpenButton = (e) => {
        e.preventDefault();
        setToggleButton(true);
    };

    const handleNameChange = (value) => {
        setValue(value);
    };

    const handleYearChange = (e) => {
        const selectedYear = Number(e.target.id);
        setYear(selectedYear);
        navigate(`/center/${selectedYear}`);
    };

    const centerYears = [2023, 2022, 2021, 2020]
    // Fetch data whenever the year changes
    useEffect(() => {
        setLoading(true); // Show loading indicator
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'x-api-key': APIKEY },
            body: JSON.stringify({ year }),
        };
        fetch(`${URL}/center`, requestOptions)
            .then((res) => res.json())
            .then((res) => setCenterData(res))
            .finally(() => setLoading(false)) // Hide loading indicator
            .catch((err) => console.log(err));
    }, [year]);

    useEffect(() => {
        // After the value updates, add the "active" class to trigger the transition.
        const listItems = document.querySelectorAll('.center__map');
        listItems.forEach(item => {
          item.classList.remove('active');
          // Force a reflow if necessary (e.g., via item.offsetWidth) then:
          setTimeout(() => item.classList.add('active'), 50);
        });
      }, [value]);

      
    const navigation = (d) => {
        return d.ancestors().reverse().slice(1).map((d) => (
            <li className='center__map' key={d.data.name}>
                <span className='center__span'>&#8595;</span>
                <p className='center__text'>{d.data.name}</p>
                <p className='center__text-value'>({f(p(d.value))} млн руб.)</p>
                <div className='center__arrow'></div>
            </li>
        ));
    };
    useEffect(() => {
        const container = document.querySelector('.center__test');
        if (container) {
            container.scrollLeft = container.scrollWidth; // Scrolls to the last item
        }
    }, [value]); // Triggers when value updates

    useEffect(() => {
        const textElement = document.querySelector('.center__text');
        if (textElement) {
            textElement.scrollTop = 0; // Resets scroll position to top
        }
    }, [value]); // Runs when text content updates
    
    return (
        <>
            <Helmet>
                <title>{`Федеральный бюджет России за ${year} год`}</title>
                <meta name="description" content={`Интерактивная визуализация федерального бюджета России за ${year} год.`}/>
                <meta property="og:title" content={`Федеральный бюджет России за ${year} год`} />
                <meta property="og:description" content={`Интерактивная визуализация федерального бюджета России за ${year} год.`} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={`https://budgetdata.ru/center/${year}`} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={`/center/${year}`} />
                <script type="application/ld+json">
                {JSON.stringify(centerSchema(year))}
                </script>
            </Helmet>
            <section className="center__page">
            <div className="center">
                <div>
                    <div className='center__dropdown' onMouseLeave={handleCloseButton} >
                        <button id={"0"} className="center__dropdown-article-button" ref={buttonRef} onClick={handleOpenButton} onMouseMove={handleOpenButton}>
                            Год&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </button>
                        
                            <ul className={`center__dropdown-list center__dropdown-list_years  ${toggleButton ? "center__dropdown-list_active" : ""} `} ref={buttonRef} onClick={handleCloseButton}>
                                {centerYears.map((y) => (
                                    <li className="center__dropdown-item" key={y}  onClick={handleYearChange}>
                                        <button id={y} className='center__dropdown-button center__dropdown-text' >
                                            {y}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                    </div>
                    <div className="center__panel" ref={panelRef}>
                    <p className="center__explanation">
                    Кликните <span className="center__highlight">дважды</span> - перейдете <span className="center__highlight">на следующий уровень</span>,<br />
                    <span className="center__highlight">один раз</span> - посмотрите <span className="center__highlight">цифры</span>
                    </p>
                        <ul className='center__test'>
                            <p className='center__value'>{`Российская Федерация, ${year}, млн руб`}</p>
                            {value}
                        </ul>
                    </div>
                    </div>
                    <div className="center__treemap">
                        {loading ? (
                            <>
                            <Loading center={"yes"} />
                            </>
                        ) : (
                            <Treemap navigation={navigation} onChange={handleNameChange} data={centerData} />
                        )}
                    </div>
                    </div>
                    <div className="regions__linegraph">
                        
                    {loading ? (
                        <Loading center={"yes"} />
                        ) : (
                        <LineGraph name={'Федерального бюджета Российской Федерации'} center={"yes"}  />
                    )}
                    </div>
            </section>
        </>
    );
}

export default Center;
