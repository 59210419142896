import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { extraSchema } from '../../utils/schema';
import Treemap from '../Treemap/Treemap';
import { s, p, f } from '../../utils/constants/formatSpecifiers';
import { FUNDS, URL, APIKEY } from '../../utils/constants/constants';
import { Helmet } from 'react-helmet-async'; 
import image from '../../images/logo.svg'; 
import Loading from "../Loading/Loading";
import "./Extra.css";

export default function Extra() {
  const { year: yearParam } = useParams();
  const navigate = useNavigate();
  const [openButton, setOpenButton] = useState({ id: 0, value: false });
  const [extraData, setExtraData] = useState(null);
  // const [visibleNodes, setVisibleNodes] = useState([]); // Store visible nodes
  // const [showTable, setShowTable] = useState(false); // Toggle between treemap and table view
  const [extraName, setExtraName] = useState(null);
  const [toggleButton, setToggleButton] = useState(false);
  const [index, setIndex] = useState(0);
  const [year, setYear] = useState(Number(yearParam) || 2020); 
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [currentNode, setCurrentNode] = useState(null); // Track current node in Treemap
  const buttonRef = useRef(null);
  const panelRef = useRef();

  // const handleVisibleNodesChange = (nodes, currentNode) => {
  //   setVisibleNodes(nodes);
  //   setCurrentNode(currentNode); // Save the current node
  // };

  // Sync year in the URL whenever it changes
  useEffect(() => {
    if (yearParam && Number(yearParam) !== year) {
      setYear(Number(yearParam));
    }
  }, [yearParam]);

  useEffect(() => {
    navigate(`/extra/${year}`);
  }, [year, navigate]);

  const handleNameChange = (value) => {
    setValue(value);
  };
  
  useEffect(() => {
    if (panelRef.current) {
        panelRef.current.scrollTop = panelRef.current.scrollHeight;
    }
  }, [value]); // or [centerData] if that's more appropriate
  // Fetch data whenever the `index` or `year` changes
  useEffect(() => {
    setLoading(true); 
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-api-key': APIKEY },
      body: JSON.stringify({ counter: index, year }),
    };
    fetch(`${URL}/extra`, requestOptions)
      .then(res => res.json())
      .then(res => {
        setExtraData(res.data);
        setExtraName(res.name);
        setLoading(false); 
      })
      .catch(() => setLoading(false));
  }, [index, year]);

  const handleOpenButton = (e) => {
    e.preventDefault(); 
    setToggleButton(true);
    setOpenButton(e.target.id);
  };

  const handleCloseButton = () => {
    setToggleButton(false);
  };
  
    useEffect(() => {
        // After the value updates, add the "active" class to trigger the transition.
        const listItems = document.querySelectorAll('.center__map');
        listItems.forEach(item => {
          item.classList.remove('active');
          // Force a reflow if necessary (e.g., via item.offsetWidth) then:
          setTimeout(() => item.classList.add('active'), 50);
        });
      }, [value]);

      
    const navigation = (d) => {
        return d.ancestors().reverse().slice(1).map((d) => (
            <li className='center__map' key={d.data.name}>
                <span className='center__span'>&#8595;</span>
                <p className='center__text'>{d.data.name}</p>
                <p className='center__text-value'>({f(p(d.value))} млн руб.)</p>
                <div className='center__arrow'></div>
            </li>
        ));
    };

    useEffect(() => {
        const container = document.querySelector('.extra__test');
        if (container) {
            container.scrollLeft = container.scrollWidth; // Scrolls to the last item
        }
    }, [value]); // Triggers when value updates

    useEffect(() => {
        const textElement = document.querySelector('.center__text');
        if (textElement) {
            textElement.scrollTop = 0; // Resets scroll position to top
        }
    }, [value]); // Runs when text content updates
    
  return (
    <>
      <Helmet>
        <title>{`Доходы и расходы внебюджетных фондов России - ${year.toString()}`}</title>
        <meta name="description" content="Cколько тратит и зарабатывает Пенсионный фонд России (ПФР)? Cколько тратит и зарабатывает Фонд обязательного медицинского страхования России (ФОМС)? Cколько тратит и зарабатывает Фонд социального страхования России (ФСС)?" />
        <meta property="og:title" content="Внебюджетные фонды России" />
        <meta property="og:description" content="Визуализация доходов и расходов внебюджетных фондов России" />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={`https://budgetdata.ru/extra/${year}`} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`/extra/${year}`} />
        <script type="application/ld+json">
      {JSON.stringify(extraSchema(year))}
    </script>
      </Helmet>
      
      <section className="extra">
        <div>
        <div className="extra__buttons">
          <div className='extra__dropdown'>
            <button id={"1"} className="extra__dropdown-article-button" ref={buttonRef} onClick={handleOpenButton} onMouseMove={handleOpenButton}>
              Фонд
            </button>
            <ul className={`extra__dropdown-list  ${toggleButton && openButton ===  "1" ? "extra__dropdown-list_active" : ""}`} ref={buttonRef} onClick={handleCloseButton} onMouseLeave={handleCloseButton}>
                {FUNDS.map((item) => (
                  <li key={item.id} className="extra__dropdown-item">
                    <button className='extra__dropdown-button extra__dropdown-text' onClick={() => setIndex(item.id)}>
                      <pre className='extra__dropdown-text'>{item.name_ru}</pre>
                    </button>
                  </li>
                ))}
              </ul>
          </div>
          <div className='extra__dropdown'>
            <button id={"2"} className="extra__dropdown-article-button" ref={buttonRef} onClick={handleOpenButton} onMouseMove={handleOpenButton}>
              Год
            </button>
              <ul className={`extra__dropdown-list extra__dropdown-list_years ${toggleButton && openButton ===  "2" ? "extra__dropdown-list_active" : ""}`} ref={buttonRef} onClick={handleCloseButton} onMouseLeave={handleCloseButton}>
                <li className="extra__dropdown-item">
                  <button className='extra__dropdown-button extra__dropdown-text' onClick={() => setYear(2020)}>
                    2020
                  </button>
                </li>
              </ul>
          </div>
        </div>
        <div className="extra__panel" ref={panelRef}>
        <p className="extra__explanation">{`Кликните дважды - перейдете на следующий уровень,\n один раз - посмотрите цифры`}</p>
            <ul className='extra__test'>
              <p className='extra__value'>{extraName}, {year}, млн руб.</p>
              {value}
            </ul>
          </div>
          </div>
          <div className="extra__treemap">
            {loading ? (
              <Loading/>
            ) 
            : (
              <>
              <Treemap
                navigation={navigation}
                onChange={handleNameChange}
                data={extraData}
              />
              </>
            )}
          </div>
      </section>
    </>
  );
}
